import React, { ReactNode } from "react"

import modalImageMobile from "~/components/Modals/AccountModal/assets/modal-image-mobile.jpg"
import noModalImageDesktop from "~/components/Modals/AccountModal/assets/nomodal-image-desktop.jpg"
import { Col, Heading, Row } from "~/components/ui"

import css from "./UserFormTemplate.module.scss"

interface UserFormTemplateProps {
  title?: string
  Form: ReactNode
}

function UserFormTemplate({ title, Form }: UserFormTemplateProps) {
  return (
    <Row className={css.root}>
      <Col
        width={[0, 5]}
        className={css.desktopImageContainer}
        style={{ backgroundImage: `url(${noModalImageDesktop})` }}
      />
      <Col
        width={[12, 0]}
        className={css.mobileImageContainer}
        style={{ backgroundImage: `url(${modalImageMobile})` }}
      />
      <Col width={[12, 7]} className={css.formContent}>
        <Heading
          className={css.title}
          align="center"
          uppercase
          variant="modal"
          color="original"
        >
          {title}
        </Heading>
        <div className={css.formWrap}>{Form}</div>
      </Col>
    </Row>
  )
}

export default UserFormTemplate
