import React from "react"

import { useIntl } from "react-intl"

import { ResetPasswordForm } from "~/components/Forms"
import SEO from "~/components/Seo/Seo"
import UserFormTemplate from "~/components/UserFormTemplate/UserFormTemplate"
import withTemplate, {
  TemplateDefaultPropTypes,
} from "~/templates/withTemplate"

const ResetPassword = (props: TemplateDefaultPropTypes) => {
  const intl = useIntl()
  const title = intl.formatMessage({ id: "profile/text:reset-my-password" })
  const paths = props.location.pathname.split("/")

  return (
    <>
      <SEO title={title} />

      <UserFormTemplate
        title={title}
        Form={<ResetPasswordForm userToken={paths[paths.length - 2]} />}
      />
    </>
  )
}

export default withTemplate(ResetPassword)
